import React from 'react'
import _map from 'lodash/map'
import _get from 'lodash/get'

import Layout from '../components/layout'
import SEO from '../components/seo'
import { graphql } from 'gatsby'
import utils from '../utils'

export const query = graphql`
	query RetailOSQuery {
		prismic {
      retailos(lang: "en-gb", uid: "retailos") {
				...RetailOSFragment
			}
		}
	}
`

const RetailOS = ({ data, location }) => {
  const doc = data.prismic.retailos

  if (!doc) return null

  const { page_title, body, meta_title, meta_description, meta_keywords, meta_image, meta, no_index } = doc

  const Body = (body) => _map(body, (slice, index) => utils.sliceRenderer({ ...slice, key: index, pageTitle: page_title }))

  return (
    <Layout>
      <SEO
        title={meta_title}
        description={meta_description}
        keywords={meta_keywords}
        image={meta_image}
				url={_get(location, 'href')}
        meta={meta}
        noIndex={no_index} />
      <Body {...body} />
    </Layout>
  )
}

export default RetailOS
